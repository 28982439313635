<template>
  <content-wrapper>
    <object-summary />
  </content-wrapper>
</template>

<script>
import ContentWrapper from "@/components/Wrappers/ContentWrapper";
import ObjectSummary from "@/components/ObjectSummary";
import NearObjects from "@/components/NearObjects";

export default {
  components: {
    ContentWrapper,
    ObjectSummary,
  },
};
</script>


